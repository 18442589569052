import * as React from "react"
import { withPrefix, Link } from "gatsby"
import Helmet from "react-helmet"

import Layout from "@components/layout"
import SEO from "@components/seo"

const SecondPage = () => (
  <>
  <Layout>
    <SEO title="Page two" />
    <h1 className={{display: "block"}}>Hi from the second page</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link>
    <Helmet>
      <script src={withPrefix('chatbot.js')} type="text/javascript" />
    </Helmet>
  </Layout>
  <h1 style={{display: "block"}}>Hi from the second page</h1>

  </>
)

export default SecondPage
